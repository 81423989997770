import React, { useEffect } from "react"
import { Person } from "../App"
import { NetsuiteIFrame } from "../components/NetsuiteIframe"
import { Layout } from "../components/Layout"
import { useHistory } from "react-router-dom"
import { RunIdleTimer } from "../components/RunIdleTimer"

interface UnionNetsuiteFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  resetPersonalDetails: (completeReset?: boolean) => void
}

export const UnionNetsuiteForm = ({
  setPerson,
  person,
  resetPersonalDetails
}: UnionNetsuiteFormProps) => {
  useEffect(() => {
    // when it mounts, null the Branch ID so that there isn't an issue of someone going back then forward
    // and being signed up to the wrong branch
    setPerson((oldPerson: Person) => {
      return {
        ...oldPerson,
        branch_id: null
      }
    })
  }, [setPerson])
  const { push } = useHistory()
  useEffect(() => {
    if (!person.people_id || !person.person_id) {
      push("/")
    }
  }, [person.people_id, person.person_id, push])
  return (
    <>
      <RunIdleTimer resetPersonalDetails={() => resetPersonalDetails(true)} />
      <Layout
        heading="Complete your application"
        sectionStep={3}
        person={person}
        setPerson={setPerson}
      >
        <></>
      </Layout>
      <NetsuiteIFrame {...person}></NetsuiteIFrame>
    </>
  )
}
